// React
import { useEffect, useState } from "react";

// Constants
import { sexs } from "assets/constants";

// Current User
import useUser from "assets/hooks/useUser";

// Material
import { Button, Grid, Typography, Paper } from "@mui/material";

// Routing
import { useParams, useHistory } from "react-router-dom";

// Theming
import theme from "theme";

// Axios
import {
  guardian,
  patchUser,
  patchGuardian,
  deleteUser,
} from "assets/plugins/axios";

// Components
import Loading from "components/Loading";
import DisplayEditField from "components/DisplayEditField";
import DisplayAutoComplete from "components/DisplayAutoComplete";
import DisplayDatePicker from "components/DisplayDatePicker";
import DisplayEditAvatar from "components/DisplayEditAvatar";
import CollapseSection from "components/CollapseSection";
import Alert from "components/Alert";
import Error from "components/Error";
import ConfirmDialog from "components/ConfirmDialog";

// Functions
import { getLabel } from "assets/functions";

// Date fns
import { parse } from "date-fns";

// Log
import { logger } from "assets/plugins/log";

const AdminGuardian = () => {
  const { id } = useParams();
  const history = useHistory();

  const { currentUser } = useUser();

  const editPermission =
    currentUser.claims.permissions.includes("change_guardian");
  const deletePermission =
    currentUser.claims.permissions.includes("delete_guardian");

  const [GuardianState, setGuardianState] = useState({});
  const [InitialLoadingState, setInitialLoadingState] = useState(true);
  const [LoadErrorState, setLoadErrorState] = useState({
    status: false,
    message: "",
  });
  const [ErrorState, setErrorState] = useState({
    status: false,
    message: "",
  });
  const [ConfirmDialogState, setConfirmDialogState] = useState({
    open: false,
    loading: false,
    title: "",
    description: "",
    confirmText: "",
    cancelText: "",
    onConfirm: () => {},
  });

  const fieldsMap = {
    id: {
      fieldName: "id",
      expand: "",
    },
    "user.id": {
      fieldName: "user.id",
      expand: "user",
    },
    "user.uid": {
      fieldName: "user.uid",
      expand: "user",
    },
    "user.email": {
      fieldName: "user.email",
      expand: "user",
    },
    "user.first_name": {
      fieldName: "user.first_name",
      expand: "user",
    },
    "user.second_name": {
      fieldName: "user.second_name",
      expand: "user",
    },
    "user.last_name": {
      fieldName: "user.last_name",
      expand: "user",
    },
    "user.second_last_name": {
      fieldName: "user.second_last_name",
      expand: "user",
    },
    "user.cellphone": {
      fieldName: "user.cellphone",
      expand: "user",
    },
    "user.sex": {
      fieldName: "user.sex",
      expand: "user",
    },
  };

  const initialFields = [];
  const initialExpand = [];

  for (const field of Object.values(fieldsMap)) {
    initialFields.push(field.fieldName);
    if (!initialExpand.includes(field.expand)) {
      initialExpand.push(field.expand);
    }
  }

  const params = {
    id,
    fields: initialFields.toString(),
    expand: initialExpand.toString(),
  };

  useEffect(() => {
    const getGuardian = async () => {
      try {
        const { data } = await guardian({
          token: currentUser.token,
          params,
        });

        setGuardianState({
          user_uid: data.user.uid,
          user_id: data.user.id,
          id: data.id,
          first_name: data.user.first_name,
          second_name: data.user.second_name,
          last_name: data.user.last_name,
          second_last_name: data.user.second_last_name,
          email: data.user.email,
          cellphone: data.user.cellphone,
          sex: data.user.sex,
          imageUrl: `https://api.dicebear.com/7.x/bottts/svg?seed=${data.user.uid}`,
        });
      } catch (error) {
        setLoadErrorState({
          status: true,
          message: error.message,
        });
      } finally {
        setInitialLoadingState(false);
      }
    };

    getGuardian();
    logger("[Admin Guardian] page loaded.", {
      payload: { currentUser },
      source: `/admin/guardian/${id}`,
    });
  }, [id, currentUser.token]);

  const onEditSave = (data) => {
    setGuardianState({
      ...GuardianState,
      ...data,
    });
  };

  const handleDeleteUser = () => {
    const guardianName =
      `${GuardianState.first_name} ${GuardianState.second_name} ${GuardianState.second_last_name} ${GuardianState.second_last_name}`
        .trim()
        .replace("  ", " ");
    setConfirmDialogState({
      open: true,
      loading: false,
      title: "Eliminar acudiente",
      description: `¿Está seguro que desea eliminar al acudiente ${guardianName}?`,
      confirmText: "ELIMINAR",
      cancelText: "CANCELAR",
      onConfirm: async () => {
        setConfirmDialogState({
          ...ConfirmDialogState,
          loading: true,
        });
        try {
          await deleteUser({
            token: currentUser.token,
            id: GuardianState.user_id,
          });
          setConfirmDialogState({
            ...ConfirmDialogState,
            open: false,
            loading: false,
          });
          history.push("/admin/guardians");
        } catch (error) {
          setConfirmDialogState({
            ...ConfirmDialogState,
            open: true,
            loading: false,
          });
        }
      },
    });
  };

  if (InitialLoadingState) return <Loading />;
  if (LoadErrorState.status)
    return <Error errorMessage={LoadErrorState.message} />;

  return (
    <Grid container spacing={2}>
      {/* Header */}
      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{
            padding: "3%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: theme.palette.primary.light,
            borderRadius: "30% 70% 70% 30% / 30% 30% 70% 70%",
          }}
        >
          <DisplayEditAvatar
            userUid={GuardianState.user_uid}
            imageUrl={GuardianState.imageUrl}
            token={currentUser.token}
            disabled
          />
          <Typography variant="h1">{`${GuardianState.first_name} ${GuardianState.last_name}`}</Typography>
        </Paper>
      </Grid>
      {/* Acudiente  */}
      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{
            padding: "3%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CollapseSection title="Acudiente">
            <Grid container spacing={5}>
              <Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
                <DisplayEditField
                  patch={(value) =>
                    patchUser({
                      token: currentUser.token,
                      id: GuardianState.user_id,
                      data: { first_name: value },
                    })
                  }
                  type="text"
                  label="Nombre"
                  defaultValue={GuardianState.first_name}
                  name="first_name"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
                <DisplayEditField
                  patch={(value) =>
                    patchUser({
                      token: currentUser.token,
                      id: GuardianState.user_id,
                      data: { second_name: value },
                    })
                  }
                  type="text"
                  label="Segundo Nombre"
                  defaultValue={GuardianState.second_name}
                  name="second_name"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
                <DisplayEditField
                  patch={(value) =>
                    patchUser({
                      token: currentUser.token,
                      id: GuardianState.user_id,
                      data: { last_name: value },
                    })
                  }
                  type="text"
                  label="Apellido"
                  defaultValue={GuardianState.last_name}
                  name="last_name"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
                <DisplayEditField
                  patch={(value) =>
                    patchUser({
                      token: currentUser.token,
                      id: GuardianState.user_id,
                      data: { second_last_name: value },
                    })
                  }
                  type="text"
                  label="Segundo Apellido"
                  defaultValue={GuardianState.second_last_name}
                  name="second_last_name"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
                <DisplayEditField
                  patch={(value) =>
                    patchUser({
                      token: currentUser.token,
                      id: GuardianState.user_id,
                      data: { email: value },
                    })
                  }
                  type="email"
                  label="Correo electrónico"
                  defaultValue={GuardianState.email}
                  name="email"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3} lg={3} xl={2}>
                <DisplayEditField
                  patch={(value) =>
                    patchUser({
                      token: currentUser.token,
                      id: GuardianState.user_id,
                      data: { cellphone: value },
                    })
                  }
                  type="text"
                  label="Celular"
                  defaultValue={GuardianState.cellphone}
                  name="cellphone"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={6} sm={2} md={2} lg={3} xl={2}>
                <DisplayAutoComplete
                  patch={(value) =>
                    patchGuardian({
                      token: currentUser.token,
                      id: GuardianState.id,
                      data: { sex: value },
                    })
                  }
                  options={sexs}
                  label="Sexo"
                  defaultValue={GuardianState.sex}
                  name="sex"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
            </Grid>
          </CollapseSection>
        </Paper>
      </Grid>
      {deletePermission && (
        <Grid item xs={12} display="flex" justifyContent="end">
          <Button variant="contained" color="error" onClick={handleDeleteUser}>
            ELIMINAR ACUDIENTE
          </Button>
        </Grid>
      )}
      <ConfirmDialog
        open={ConfirmDialogState.open}
        loading={ConfirmDialogState.loading}
        title={ConfirmDialogState.title}
        description={ConfirmDialogState.description}
        onConfirm={ConfirmDialogState.onConfirm}
        onClose={() =>
          setConfirmDialogState({
            open: false,
            loading: false,
          })
        }
        confirmText={ConfirmDialogState.confirmText}
        confirmColor="error"
        cancelText={ConfirmDialogState.cancelText}
      />
      <Alert
        open={ErrorState.status}
        severity="error"
        message={ErrorState.message}
        onClose={() =>
          setErrorState({
            status: false,
            message: "",
          })
        }
      />
    </Grid>
  );
};

export default AdminGuardian;
